import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { MDBContainer, MDBRow, MDBCol, MDBSmoothScroll } from 'mdbreact'
import OfficeLocations from '../components/columnAlternatingOffices'
import OfficeDepartments from '../components/cardIconsOffices'
import FormMarketo from '../components/formMarketo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/pro-solid-svg-icons'
import FadeInWhenVisible from '../components/fadeInWhenVisible'

const Contact = ({ data }) => {
  const post = data.markdownRemark
  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.gatsbyImageData}
            foreimage={null}
            alt={post.frontmatter.alttext}
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            ctatext={post.frontmatter.ctatext}
            ctaslug={post.frontmatter.ctaslug}
            type="root"
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <FadeInWhenVisible>
              <MDBContainer>
                <MDBRow>
                  <MDBCol lg="8" className="md-0 mb-5">
                    <FormMarketo formId="1609" />
                  </MDBCol>

                  <MDBCol lg="4">
                    <div className="contact-address">
                      <h3 className="font-alt text-center font-w-700 letter-spacing-1 my-2 title-xs-medium title-large">
                        Quick links
                      </h3>
                      <hr className="my-4" />
                      <ul className="fa-ul font-w-700 text-large my-5">
                        <li className="pb-3">
                          <span className="fa-li">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              size="2x"
                              className="mr-5 ico-color va-m"
                            />
                          </span>
                          <MDBSmoothScroll to="locations" offset={-40} className="text-capitalize text-medium pt-0" >
                            Office locations
                            <p className="text-gray-dark font-alt font-w-400 text-medium letter-spacing-1">
                              Where are we?
                            </p>
                          </MDBSmoothScroll>
                        </li>
                        <li>
                          <span className="fa-li">
                            <FontAwesomeIcon
                              icon={faPhoneAlt}
                              size="2x"
                              className="mr-5 ico-color va-m"
                            />
                          </span>
                          <MDBSmoothScroll to="departments" offset={-40} className="text-capitalize text-medium pt-0" >
                            Department details
                            <p className="text-gray-dark font-alt font-w-400 text-medium letter-spacing-1">
                              Our contacts
                            </p>
                          </MDBSmoothScroll>
                        </li>
                      </ul>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>

          <section className="bg-light-blue" id="locations">
            <FadeInWhenVisible>
              <MDBContainer>
                <h2 className="font-alt text-center font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large pb-5">
                  {post.frontmatter.locations.title}
                </h2>
                {post.frontmatter.locations.offices.map((officelocations, index) => (
                  <OfficeLocations
                    key={index}
                    office={officelocations.office}
                    address={officelocations.address}
                    tel={officelocations.tel}
                    fax={officelocations.fax}
                    lat={officelocations.lat}
                    lng={officelocations.lng}
                    placement={officelocations.placement}
                  />
                ))}
              </MDBContainer>
            </FadeInWhenVisible>
          </section>

          <section className="bg-white" id="departments">
            <FadeInWhenVisible>
              <MDBContainer>
                <h2 className="font-alt text-center font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.departments.title}
                </h2>
                {post.frontmatter.departments.contactdeets && (
                  <MDBRow>
                    <>
                      {post.frontmatter.departments.contactdeets.map((details, index) => (
                        <OfficeDepartments
                          key={index}
                          depttitle={details.depttitle}
                          tel={details.tel}
                          tel1={details.tel1}
                          fax={details.fax}
                          email={details.email}
                          suptext={details.suptext}
                          image={details.image.childImageSharp.gatsbyImageData}
                          alt={details.alttext}
                        />
                      ))}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default Contact

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "contact" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        locations {
          title
          offices {
            office
            address
            tel
            fax
            lat
            lng
            placement
          }
        }
        departments {
          title
          contactdeets {
            depttitle
            tel
            tel1
            fax
            email
            suptext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 85, height: 85, quality: 90) 
              }
            }
          }
        }
      }
      html
    }
  }
`